@import "@styles/profilemixins";

.infoContainer {
  margin-top: 8px;
}

.infoRow {
  @include infoRow();
}

.userInfo {
  @include userInfo();
}

.ctaTitle {
  @include ctaTitle();
}

.editCta {
  @include editCta();
}


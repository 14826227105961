@import '@separate/v4/styles/responsive/devicesMixins';

.headerBar {
  background-color: #FFFF;
  height: 65px;
  padding: 5px 10px;
  box-shadow: 0px 14px 43px 0px rgba(145, 187, 230, 0.36);
  @media screen and (max-width: 1200px) {
    height: 56px;
  }
  .container {
    flex-wrap: nowrap;
  }
}

.brand {
  font-size: 20px;
  cursor: pointer;
}

.toggle {
  border: none;
  &:focus, &:active {
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
  }
}

.offCanvas {
  :global([class*="offcanvas-header"]) {
    height: 56px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  hr {
    border: 1px solid white;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;
    opacity: 0;
    @include device-min("md") {
      display: none;
    }
  }
}


//Pill radio/checkbox
@mixin pillCheckButton-unchecked($color) {
  background-color: var(--color-white);
  color: $color;
  border: 2px solid;
  &:hover {
    background-color: $color;
    border-color: $color;
    color: var(--color-white);
  }
}

@mixin pillCheckButton-checked($color) {
  background-color: $color;
  color: var(--color-white);
  border-color: $color;
}



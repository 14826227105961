@import "@styles/modalmixins";

$backdrop-color: var(--color-modal-backdrop);

.modal {
  @include modal-blur-fullscreen();
  text-align: center;
  .modalContent {
    @include modal-flex-cols();
  }
}

.modalBackdrop {
  @include modal-backdrop($backdrop-color);
}

.title {
  font-size: 30px;
}

.message {
  font-size: 20px;
}

.dismiss {
  background-color: #fefefe;
  color: var(--color-secondary);
  font-size: 14px;
}

.link {
  text-align: center;
}

@import "@styles/profilemixins";

.ctaTitle {
  @include ctaTitle();
}

.editCta {
  @include editCta();
}

.container {
  height: 64vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .modalCTAs {
    text-align: center;

    p {
      font-weight: 510;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      width: 80%;
      margin-top: 32px;
      color: var(--color-text);
    }

    .logoutButton {
      background-color: #fff;
      color: var(--color-secondary);
      font-size: 14px;
      height: 42px;
      width: 251px;
      border-radius: 30px;
      border: 1px solid var(--color-secondary);
    }

    a {
      font-size: 12px;
      margin-top: 8px;
      text-decoration: none;
      color: var(--color-secondary);
    }

    @media screen and (max-width: 375px) {
      padding: 30px 0;
    }
  }
}

.soft-keyboard-open {
  .messageInputField {
    bottom: 0 !important;
  }

  &.ios-addressbar-bottom {
    .messageInputField {
      bottom: 20px !important;
    }
  }
}

.addDependents {
  padding: 25px;
  border: 1px solid var(--color-progress-background);
  border-radius: 15px;

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: var(--color-premium-gray);
  }

  .cta {
    margin: 10px;
    font-size: 14px;
    width: 200px;
    background-color: none !important;
    border: none !important;
  }
}

.paymentPricing {
  .fullAnnualPrice {
    color: var(--color-premium-gray);
    font-size: 14px;
    font-weight: 600;
    text-decoration: line-through;
    text-decoration-thickness: 2px;
    margin-right: 5px;
  }

  .price {
    font-size: 22px;
    font-weight: 700;
    line-height: 26.86px;
    padding-right: 4px;
  }

  .perMonthOrYear {
    font-size: 10px;
    font-weight: 600;
    line-height: 11.93px;
  }
}

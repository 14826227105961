@import '@v4/styles/variables.scss';

.footer {
  background: $color_primary;
  hr {
    color: $background_secondary;
  }
  :global([class*="FooterLinks_footerLinks"]) {
    :global([class*="FooterLinks_title"]) {
      color: $color_white;
    }
    a {
      color: $color_white;
    }
  }
}

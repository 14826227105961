$bg-alpha: 0.2;

$color-primary: #00D984;
$color-primary-bg: rgb($color-primary, $bg-alpha);
$color-primary-dark: #007E84;
$color-primary-light: #07D170;
$color-primary-fade: #E6fAf7;

$color-secondary: #007E84;
$color-secondary-bg: rgb($color-secondary, $bg-alpha);
$color-secondary-blue: #1F82BF;
$color-body: #535353;
$color-white: #FFFFFF;
$color-background-white: #F5F5F5;
$color-base-white: #FBFCFF;
$color-gray: #F2F4FB;
$color-gray-dark: #D1D5E2;
$color-gray-dark-bg: rgb($color-gray-dark, $bg-alpha);
$color-gray-darker: #9d9eb0;
$color-premium-gray: #6E91B1;
$color-premium-gray-bg: rgb($color-premium-gray, $bg-alpha);
$color-error: #ED3434;
$color-base-gray:#FAFBFD;
$color-background-gray: #FCFAFB;
$color-button-background-red: #EC4E58;
$color-progress-background: #D5E3ED;
$color-medium-gray: #DBDBDB;
$color-dark-gray: #9CA0A3;

$color-link: #0070f3;

$color-text: #102B3F;
$color-text-dark: #262350;
$color-text-dark-blue: #01044B;
$color-text-light: #FBFCFF;
$color-text-gray: #748083;

$color-dark-green: #032C36;
$color-light-green: #476167;

// NEW WT COLOR SCHEMES
$color-primary-welcome-blue: #0c74fe;
$color-primary-welcome-dark-blue: #004284;
$color-secondary-background-blue: #F2F7FA;
$color-secondary-charcoal-black: #2A2E31;
$color-secondary-charcoal-gray: #898F94;
$color-secondary-dark-blue: #1E384A;
$color-secondary-dark-gray: #2A2E31;
$color-secondary-fade-gray: #D0E5FE;
$color-secondary-light-blue: #CAE7FF;
$color-secondary-medium-blue: #429EFB;
$color-secondary-text-blue: #1883FF;
$color-secondary-light: #D5FFEF;

// FONT FAMILIES
$tiempos-headline-font: 'Tiempos Headline', serif;
$neue-haas-grotesk-font: 'Neue Haas Grotesk Text Pro', sans-serif;

:root {
  // COLORS
  --color-primary: #{$color-primary};
  --color-primary-dark: #{$color-primary-dark};
  --color-primary-light: #{$color-primary-light};
  --color-primary-fade: #{$color-primary-fade};

  --color-secondary: #{$color-secondary};
  --color-secondary-light: #{$color-secondary-light};
  --color-secondary-blue: #{$color-secondary-blue};
  --color-body: #{$color-body};
  --color-white: #{$color-white};
  --color-background-white: #{$color-background-white};
  --color-base-white: #{$color-base-white};
  --color-gray: #{$color-gray};
  --color-gray-dark: #{$color-gray-dark};
  --color-gray-dark-bg: #{$color-gray-dark-bg};
  --color-gray-darker: #{$color-gray-darker};
  --color-premium-gray: #{$color-premium-gray};
  --color-premium-gray-bg: #{$color-premium-gray-bg};
  --color-error: #{$color-error};
  --color-base-gray: #{$color-base-gray};
  --color-background-gray: #{$color-background-gray};
  --color-button-background-red: #{$color-button-background-red};
  --color-progress-background: #{$color-progress-background};
  --color-medium-gray: #{$color-medium-gray};
  --color-dark-gray: #{$color-dark-gray};

  --color-link: #{$color-link};
  --color-link-dark: "";
  --color-link-light: "";

  --color-text: #{$color-text};
  --color-text-dark: #{$color-text-dark};
  --color-text-dark-blue: #{$color-text-dark-blue};
  --color-text-light: #{$color-text-light};
  --color-text-gray: #{$color-text-gray};

  --color-input-disabled: #{$color-text-gray};
  --color-gradient: linear-gradient(to right, #{$color-primary}, #{$color-secondary});
  --color-gradient-bg: linear-gradient(to right, #{$color-primary-bg}, #{$color-secondary-bg});
  --color-gradient-dark: linear-gradient(to right, #{$color-primary}, #{$color-secondary});

  --color-modal-backdrop: rgba(#{$color-white}, 0.8);

  --color-border-dark: #{$color-text};

  --color-dark-green: #{$color-dark-green};
  --color-light-green: #{$color-light-green};

  // NEW WT COLOR SCHEMES
  --color-primary-welcome-blue: #{$color-primary-welcome-blue};
  --color-primary-welcome-dark-blue: #{$color-primary-welcome-dark-blue};
  --color-secondary-background-blue: #{$color-secondary-background-blue};
  --color-secondary-charcoal-black: #{$color-secondary-charcoal-black};
  --color-secondary-charcoal-gray: #{$color-secondary-charcoal-gray};
  --color-secondary-dark-blue: #{$color-secondary-dark-blue};
  --color-secondary-dark-gray: #{$color-secondary-dark-gray};
  --color-secondary-fade-gray: #{$color-secondary-fade-gray};
  --color-secondary-light-blue: #{$color-secondary-light-blue};
  --color-secondary-medium-blue: #{$color-secondary-medium-blue};
  --color-secondary-text-blue: #{$color-secondary-text-blue};

  // FONT FAMILIES
  --tiempos-headline-font: #{$tiempos-headline-font};
  --neue-haas-grotesk-font: #{$neue-haas-grotesk-font};

  //SIZES
  --header-height: 60px;
}

@import '@separate/v4/styles/buttons/buttonMixins';
@import '@separate/v4/styles/responsive/devicesMixins';
@import '@v4/styles/variables.scss';

.button {
  @include button();
  background-color: $color_primary !important;
  &:active {
    background-color: $color_primary;
    border-color:  $color_primary;
  }
  width: 100%;
  @include device-min("xl") {
    width: auto;
    min-width: 172px;
  }
}

.input {
  label, :global([class*="textLabel"]) {
    color: $header_neutral;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.28px;
    margin-bottom: 4px;
  }
  input {
    border-radius: 12px;
    border: 1px solid #E6E6E6;
    background: $color_white;
    padding: 12px 16px;
    color: $body_neutral;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
    &:focus {
      border: 1px solid $color_primary;
      box-shadow: 0px 0px 0px 3px rgba(0, 126, 132, 0.16);
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 30px $color_white inset !important;
    }
    &:disabled {
      background: $background_secondary;
      opacity: 0.5;
    }
  }
}


.stripeInputContainer {
  position: relative;

  .stripeInput {
    cursor: auto;
    font-size: 16px;

    & + label {
      display: none;
      position: absolute;
      top: 20px;
      left: 0;
      color: var(--color-secondary);
      font-size: 12px;
    }

    &.StripeElement--empty + label {
      display: block;
    }

    &.StripeElement--invalid + label {
      color: var(--color-error);
    }

    &.StripeElement--focus,
    &.StripeElement--complete,
    &.StripeElement--invalid {
      & + label {
        display: block;
        bottom: -20px;
        top: initial;
        left: initial;
        animation-name: moveLabel;
        transform-origin: left;
        animation-fill-mode: forwards;
        animation-duration: 0.5s;
      }
    }
  }
}

@keyframes moveLabel {
  0% {
    transform: translateX(-20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.paymentButton{
  height: 45px;
  border: none;
}

@import '@styles/profilemixins';

.ctaTitle {
  @include ctaTitle();
}

.editCta {
  @include editCta();
}

.container {
  min-height: 550px;
  padding-bottom: 100px;
  overscroll-behavior-y: none;
}

.addDependentsCta {
  margin-top: 20px !important;

  svg {
    g {
      path {
        fill: white;
      }
    }

  }
    circle {
      stroke: white;
    }
}

.spinner {
  color: var(--color-secondary);
}

@import "../../../../styles/_buttonmixins.scss";

.modal {
  background: rgba(16, 43, 63, 0.6);
  .content {
    border-radius: 30px;
    margin-top: 15%;
    @media screen and (min-width: 992px) {
      max-width: 480px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  transition: 0.3s ease-in-out;
}

.header {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding: 35px 24px 0px 20px;
  border: none;
  .closeButton {
    color: var(--color-secondary);
    min-width: 28px;
    cursor: pointer;
  }
}

.title {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.98px;
}

.subtitle {
  color: var(--color-premium-gray);
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-top: 8px;
}

.body {
  padding-bottom: 24px;
}

.retryButton,
.changeButton {
  margin: 12px;
  width: 289px;
}

.retryButton {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
}

.changeButton {
  @include button-theme-light(var(--color-secondary));
}

$boder-color: var(--color-secondary);
$text-color: var(--color-body);
$error-color: var(--color-error);

.selectContainer {
  width: 100%;
  padding-bottom: 25px;
  position: relative;
  .textLabel,
  .emptyLabel {
    font-size: 12px;
    line-height: 17px;
    color: $boder-color;
    padding-bottom: 5px;
    position: absolute;
    width: auto;
    z-index: 0;
    left: 0;
    top: 15%;
  }
  .emptyLabel {
    animation-name: moveLabelUp;
    transform-origin: left;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
  }
  .select {
    background-image: none !important;
    background-color: transparent !important;
    border: none;
    border-radius: 0;
    border-bottom: 3px solid $boder-color;
    font-size: 16px;
    line-height: 17px;
    color: $text-color;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 4px;
    transition: all 0.1s ease-in-out;
    position: relative;
    &.nullOption {
      color: $boder-color;
      font-size: 12px;
    }
    &:focus {
      box-shadow: none;
    }
    &:focus,
    &:not([value='']):valid {
      & + .textLabel {
        animation-name: moveLabel;
        transform-origin: left;
        animation-fill-mode: forwards;
        animation-duration: 0.5s;
      }
    }
  }
}

.isInvalid {
  color: $error-color !important;
  &.select {
    border-bottom: 3px solid $error-color;
    font-size: 12px !important;
  }
}

@keyframes moveLabel {
  0% {
    transform: translateX(-20%);
    opacity: 0;
    top: auto;
    bottom: -5px;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
    top: auto;
    bottom: -5px;
  }
}

@keyframes moveLabelUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

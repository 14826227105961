.processingModal {
  :global(.modal-body) {
    min-height: 229px;
    padding: 24px;
    text-align: center;
  }
  :global(.modal-content) {
    border-radius: 20px;
    @media screen and (min-width: 345px) {
      min-width: 343px;
    }
  }

  :global(.spinner-border) {
    color: var(--color-secondary) !important;
    width: 3rem;
    height: 3rem;
  }

  .backdrop {
    background-color: rgba(16, 43, 63, 0.7);
  }

  .body {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .header {
      color: var(--color-text);
      font-size: 16px;
      padding: 24px 0px;
    }
  }
}

@import "../../../styles/_buttonmixins.scss";

$button-color: var(--color-secondary, var(--color-secondary));
$button-shadow: 0px 4px 10px 1px rgba(157, 156, 181, 0.3);

.button {
  padding: 5px 12px;
  border-radius: 70px;
  outline: none;
  box-shadow: $button-shadow;
  &:disabled {
    opacity: 0.5;
    filter: grayscale(5%);
  }
  .spinner {
    color: inherit !important;
    position: relative;
  }
  &:hover {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
  }
}

.iconButton{
  background-color: transparent !important;
  border: none;
  padding: 0;
  width: auto;
  .spinner {
    color: $button-color;
  }
}

.pageLoader {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
  }
  .spinner {
    height: 80px;
    width: 80px;
    position: absolute;
    color: #007E84;
  }
}

.modal {
  :global([class*="modal-content"]) {
    @media only screen and (min-width: 992px) {
      max-height: 90vh;
      overflow: hidden;
    }
  }
}
.modalContent {
  min-height: 550px;
  overflow: hidden;
  overscroll-behavior: none;
  margin-top: 35px;
  padding: 0;

  .headerContent {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    .closeModal {
      color: var(--color-secondary);
    }

    .text {
      color: var(--color-secondary);

      svg {
        transform: scale(2.5);
      }

      h2 {
        font-weight: 510;
        font-size: 20px;
        line-height: 24px;
        color: #102b3f;
      }

      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #6E91B1;
      }
    }
  }

  .horizontalLine {
    height: 1px;
    width: 100vw;
    background-color: #d7d7d7;
    margin: 24px -15px 10px -15px;
  }
}

.bodyContent {
  position: relative;
}


.poderSaludSubscriptionPlan {
  border: 1px solid #D5E3ED;
  border-radius: 20px;
  margin-bottom: 30px;

  .priceContainer {
    display: flex;
    padding: 16px 16px 0 16px;

    .info {
      display: flex;
      flex-direction: column;
      margin-left: 16px;

      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: var(--color-premium-gray);
      }

      p:first-of-type {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 8px;
        color: var(--color-text)
      }

      .currency {
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
      }
      
      .date {
        text-transform: capitalize;
      }
    }

  svg {
    align-self: flex-start;
  }
 }

  .saveContainer {
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 10px 1px rgba(92, 122, 255, 0.2);
    border-radius: 10px;
    padding: 16px;
    margin: 16px;

    .info {
      margin-left: 16px;
      display: contents;

      p {
        font-size: 16px;
        line-height: 21px;
        color: var(--color-text);
        margin: 0;
      }
    }

  svg {
    margin-right: 12px;
    flex: none;
  }

  button {
    width: 40%;
    border-radius: 70px !important;
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    margin-left: auto;

    &:active {
      background-color: var(--color-secondary);
      border-color: var(--color-secondary);
    }
    
    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }
 }

 hr {
  border-top: 1px solid #D5E3ED;
  opacity: inherit;
 }
}

.subscriptionRadioCard {
  cursor: auto;

  svg {
    display: none;
  }
}

.body {
  margin: 1px 16px 16px 16px;
  padding: 0;

  .infoBanner {
    background: var(--color-base-white);
    border-color: var(--color-progress-background);
    border-style: solid;
    border-width: 1px;
    padding: 16px;
  }

  &>div:nth-child(2) {
    border-radius: 0px 0px 10px 10px;
    border-style: solid;
    border-width: 1px;
    background: var(--color-base-white);
    border-color: var(--color-progress-background);
    padding: 16px;
    margin-bottom: 30px;
  }

  .infoBanner {
    align-items: center;
    border-bottom-style: none;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
  }
}

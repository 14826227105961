@import "@styles/profilemixins";

.ctaTitle {
  @include ctaTitle();
}

.accountCancellation {
  height: 550px;

  .text {
    color: var(--color-text);
    font-size: 16px;
    font-weight: 500;

    a {
      color: var(--color-secondary);
      text-decoration: underline;
    }
  }

  ul {
    margin-left: -10px;

    li {
      font-weight: 600;
    }
  }

  .note {
    border: 1px solid var(--color-progress-background);
    border-radius: 10px;
    background-color: var(--color-base-white);
    padding: 15px;

    p {
      margin: 0;
      color: var(--color-premium-gray);
      font-weight: 600;
      font-size: 12px;

      span {
        font-weight: 400;
      }
    }
  }

  .cta {
    max-width: 279px;
  }
}

@mixin button-theme-dark($color) {
  background-color: $color;
  color: #FFFFFF;
  border: 2px solid $color;
  &:focus, &:hover, &:disabled {
    background-color: $color;
    color: #FFFFFF;
    border: 2px solid $color;
  }
  &:active {
    border: 2px solid $color;
  }
}

@mixin button-theme-light($color) {
  background-color: #FFFFFF !important;
  color: $color;
  border: 2px solid;
  &:focus, &:hover, &:disabled {
    background-color: #FFFFFF;
    color: $color;
    border: 2px solid;
  }
}

@mixin button-hover-theme($color, $theme) {
  @if $theme == dark {
    @include button-theme-dark($color);
    &:hover {
      @include button-theme-light($color);
    }
  } @else {
    @include button-theme-light($color);
    &:hover {
      @include button-theme-dark($color);
    }
  }
}

@import '@separate/v4/styles/buttons/buttonMixins';
@import '@v4/styles/variables.scss';

.title, .subtitle {
  color: var(--color-text);
  margin: 0;
}

.title {
  margin-top: 5px;
  font-size: 28px;
  font-weight: 600;
}

.subtitle {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 400;
}

.inputContainer {
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid var(--color-progress-background);
}

.cta {
  @include button();
  width: 90%;
  font-size: 14px;
  padding: 10px 25px;
  background-color: $color_primary;
  box-shadow: none;
  &:active, &:hover, &:disabled:not(.disabled) {
    background-color: $color_primary !important;
    border-color:  $color_primary;
  }
  color: $color_white;
}

.disabled {
  background-color: var(--color-medium-gray) !important;
  color: var(--color-dark-gray) !important;
}

.footer {
  span {
    font-size: 14px;
    color: var(--color-text);
  }

  .footerCta {
    margin-left: 5px;
    background-color: transparent;
    color: var(--color-secondary);
    font-size: 14px;
    box-shadow: none !important;
    border: none !important;
    border-radius: 0;
    padding: 0;
  }
}

.footer {
  font-size: 12px;
  color: var(--color-premium-gray);
  line-height: 18px;
  text-align: center;

  a {
    font-weight: 600;
    color: var(--color-secondary);
    text-decoration: underline !important;
  }
}

.placeholder {
  &::placeholder {
    font-size: 15px !important;
    color: var(--color-dark-gray) !important;
  }
}

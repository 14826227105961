@import '@styles/profilemixins';

.ctaTitle {
  @include ctaTitle();
}

.editCta {
  @include editCta();
}

.inputContainer, .paymentStyling {
  @include ctaContainerMargin();
}

.submitCTA {
  width: 251px;
  height: 42px;
  font-size: 14px;
  line-height: 17px;
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);

  @media screen and (max-width: 375px) {
    margin: 30px 0;
  }
}

.ctaMargin{
  margin: 50px 0;
}



@import "@styles/modalmixins";
@import "@styles/premiummixins";
@import "@styles/buttonmixins";

$button-color: var(--color-secondary);
$backdrop-color: var(--color-modal-backdrop);

.modal {
  @include modal-blur-fullscreen();
  text-align: center;
  .modalContent {
    @include modal-flex-cols();
  }
}

.modalBackdrop {
  @include modal-backdrop($backdrop-color);
}

.title {
  font-size: 30px;
}

.message {
  font-size: 20px;
}

.next {
  font-size: 14px;
  @include button-theme-dark($button-color);
}

.dismiss {
  font-size: 14px;
  @include button-theme-light($button-color);
}

// Columns for State and Zip
.cols {
  display: flex;
  flex-direction: row;
  width: 70%;

  @media screen and (min-width: 768px) {
    width: 100%;
  }
}

.leftColumn {
  width: 50%;
}

.rightColumn {
  width: 50%;
  margin-left: 32px;
}

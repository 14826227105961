.subscriptionOptionRadioCard {
  border-radius: 10px;
  color: var(--color-text);
  font-family: 'Helvetica Neue';
  background-color: var(--color-premium-gray-bg);
  cursor: pointer;

  @media screen and (max-width: 425px) {
    width: 95% !important;
  }

  div[class~="card"] {
    background: transparent;
    flex: 1 1 auto;
  }

  div[class~="card-body"] {
    padding-right: 4px;

    @media screen and (max-width: 375px) {
      padding: 1rem 0;
    }
  }

  .heading {
    color: var(--color-premium-gray);
    font-size: 12px;
    height: 22px;
  }

  .footer {
    font-size: 12px;
    line-height: 18px;
  }

  .flair {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-premium-gray);
    color: var(--color-white);
    font-weight: 600;
    border-radius: 4px;
    padding: 4px 8px;
  }

  .payFullPrice {
    font-weight: 600;
    color: var(--color-premium-gray);
    line-height: 25px;
  }

  svg[class~="radio-card-indicator"] {
    color: var(--color-premium-gray);
  }
}

.selected {
  border-color: var(--color-secondary);
  background: var(--color-gradient-bg);
  cursor: auto;

  .flair {
    background: var(--color-gradient);
  }

  .payFullPrice {
    color: var(--color-secondary);
  }

  svg[class~="radio-card-indicator"] {
    color: var(--color-secondary);
  }
}

.disabled {
  border-color: var(--color-gray-dark);
  background: var(--color-gray-dark-bg);
  color: var(--color-gray-dark);
  cursor: auto;

  .heading {
    color: var(--color-gray-dark);
  }

  span[class~="fullAnnualPrice"] {
    color: var(--color-gray-dark);
  }

  .flair {
    background: var(--color-gray-dark);
    color: var(--color-text-light);
  }

  .payFullPrice {
    color: var(--color-gray-dark);
  }

  svg[class~="radio-card-indicator"] {
    color: var(--color-gray-dark);
  }
}

@import "@styles/profilemixins";

.ctaTitle {
  @include ctaTitle();
}

.editCta {
  @include editCta();
}

.smsPreferences {
  height: 550px;

  .text {
    color: var(--color-text);
    font-size: 16px;
    font-weight: 500;
  }

  .checkBoxesWrapper {
    border: 1px solid var(--color-progress-background);
    border-radius: 10px;
    background-color: var(--color-base-white);
    padding: 10px;

    .checkBox {
      margin-left: 5px;

      input[type='checkbox'] {
        box-shadow: none !important;
        height: 20px;
        width: 20px;
        border: 2px solid var(--color-secondary);
        background-size: 0px 0px !important;
        transition: transform 0.2s ease-in-out;

        &:checked {
          background-size: 20px 20px !important;
          background-color: var(--color-secondary);
        }
      }

      label {
        margin-top: 3px;
        margin-left: 10px;
        font-size: 14px;
        font-weight: 600;
      }
    }

    span {
      margin-bottom: 3px;
      margin-left: 5px;
      font-size: 14px;
      font-weight: 300;
      line-height: 25px;
    }
  }

  .cta {
    max-width: 279px;
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
  }

  .accountCancellationCta {
    background-color: white !important;
    box-shadow: none !important;
    padding: 2px 5px 5px 1px !important;
    text-decoration: underline;
    border: 0 !important;
    border-radius: 0 !important;
    color: var(--color-secondary);
  }
}

.successModal {
  :global(.modal-body) {
    padding: 24px 16px;
    text-align: center;
  }
  :global(.modal-content) {
    border-radius: 20px;
  }

  .backdrop {
    background-color: rgba(16, 43, 63, 0.7);
  }

  .body {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .header {
      color: var(--color-text);
      font-size: 16px;
      padding: 16px 0px;
    }

    .bodyText {
      color: var(--color-premium-gray);
      font-size: 14px;
      padding: 0px;
      text-align: center;
    }

    .button {
      margin-top: 24px;
      min-width: 111px;
      background-color: var(--color-secondary);
      border-color: var(--color-secondary);
    }
  }
}

@import "@styles/profilemixins";

.ctaTitle {
  @include ctaTitle();
}

.editCta {
  @include editCta();
}

.infoRow {
  @include infoRow();
}

.userInfo {
  @include userInfo();
}

.addDependentsCta {
  margin: 10px 0 0 0 !important;
  background: none !important;
  box-shadow: none !important;
  border: 0 !important;
  padding: 0 !important;
  color: var(--color-secondary) !important;

  &:hover, :focus {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
  }
}

.spinner {
  color: var(--color-secondary);
}

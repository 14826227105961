.confirmModal {
  :global(.modal-body) {
    font-family: 'Helvetica Neue';
    padding: 0px;
    text-align: center;
  }
  :global(.modal-content) {
    border-radius: 20px;
  }

  .backdrop {
    background-color: rgba(16, 43, 63, 0.7);
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Helvetica Neue';

    .header {
      color: black;
      font-size: 16px;
      padding: 24px 16px;
    }

    .bodyText {
      color: var(--color-premium-gray);
      font-size: 14px;
      padding: 0px 16px;
      text-align: center;
    }

    .buttonRow {
      display: flex;
      justify-content: space-evenly;
      padding-bottom: 29px;
      padding-top: 24px;
      width: 100%;

      .cancelButton {
        background-color: transparent !important;
        border-color: transparent;
        box-shadow: none;
        color: var(--color-secondary);
      }

      .deleteButton {
        background-color: var(--color-button-background-red);
        border-color: var(--color-button-background-red);
        width: 127px;
      }
    }
  }
}

@import '@separate/v4/styles/responsive/devicesMixins';

.watermark {
  text-align: center;
  color: #ffffffb8;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.28px;
  a {
    color: #ffffffb8;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.28px;
    display: block;
    text-decoration-line: underline;
  }
}


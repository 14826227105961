.container {
  .dependentsCard {
    padding: 15px;
    border: 1px solid var(--color-progress-background);
    border-radius: 10px;

    h4, p {
      margin: 0;
    }

    h4 {
      font-size: 14px;
      font-weight: 600;
      color: var(--color-text);
    }

    p {
      font-size: 12px;
      color: var(--color-premium-gray);
    }

    .editCta {
      box-shadow: none !important;

      svg {
        circle {
          fill: var(--color-secondary);
        }
      }
    }
  }

  .newDependents {
    align-items: center;
    background-color: var(--color-white);
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(157, 156, 181, 0.25);
    display: flex;
    height: 70px;
    padding: 26px 16px;
    cursor: pointer;

    p {
      margin: 0;
    }

    .dependentIcon {
      margin-right: 16px;
    }

    .addCta {
      margin-left: auto;

      svg {
        margin: 0 0 3px 0;
      }
    }
  }
}

.description {
  margin: 0;
  font-size: 14px;

  span {
    color: var(--color-secondary);
  }
}

@import "@styles/buttonmixins";

$button-color: var(--color-secondary) !important;
$button-shadow: 0px 4px 10px 1px rgba(157, 156, 181, 0.3);

.button {
  border-radius: 70px;
  outline: none;
  box-shadow: $button-shadow;
  &:disabled {
    opacity: 0.5;
    filter: grayscale(5%);
  }
  &:focus {
    box-shadow: $button-shadow;
  }
  .spinner {
    color: inherit !important;
    position: relative;
  }
  &:hover {
    background-color: $button-color;
  }
}

.iconButton{
  background-color: transparent !important;
  border: none;
  padding: 0;
  width: auto;
  .spinner {
    color: $button-color;
  }
}

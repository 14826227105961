.modal {
  @media screen and (min-width: 768px) {
    div[class~="modal-content"] {
      border-radius: 20px;
      overflow: hidden;
    }
  }
}

.body {
  padding: 0;

  .headerBackground {
    width: 100%;

    img {
      height: 70px;
      width: 100%;
    }
  }

  .closeButton {
    position: absolute;
    top: 45px;
    right: 30px;
    height: 28px;
    width: 28px;

    svg {
      border-radius: 50%;

      circle {
        color: var(--color-secondary);
      }

      g {
        path {
          color: var(--color-secondary);
        }
      }
    }
  }
}

@import '@separate/v4/styles/responsive/devicesMixins';

.footerLinks {
  a {
    display: block;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.28px;
    margin-bottom: 12px;
    @include device-max("xl") {
      font-size: 12px;
    }
  }
}

.title {
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  @include device-max("xl") {
    font-size: 16px;
  }
}

.langSelect {
  border-radius: 20px;
}

.options {
  text-align: center;
  cursor: pointer;
}

.offset {
  position: relative;
  right: 1em;
}

.invisible {
  visibility: hidden;
}

.radioCard {
  width: 344px;
  height: 103px;
  border-style: solid;
  border-radius: 10px;
  border-width: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .content {
    border: 0;
  }

  .indicator {
    margin-top: 16px;
    margin-right: 16px;

    @media screen and (max-width:375px) {
      margin-right: -5px;
    }
  }
}

.wrapper {
  position: sticky;
  top: 60px;
  padding: 1.3rem;
  background-color: var(--color-premium-gray);
  z-index: 5;
}

.textContent {
  margin: 0;
  color: white;
  font-size: 14px;
  font-weight:  400;
}

.payButton {
  margin-left: 20px;
  width: 130px;
  min-width: 100px;
  padding: 7px 20px;
  font-size: 14px;
  background-color: var(--color-button-background-red) !important;
  border: 0 !important;
  box-shadow: 0px 4px 10px 1px rgba(157, 156, 181, 0.3);
}

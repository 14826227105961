.body {
  @media screen and (min-width: 360px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  @media screen and (min-width: 375px) {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  @media screen and (min-width: 390px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  @media screen and (min-width: 540px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@import '@separate/v4/styles/responsive/devicesMixins';

.footer {
  min-height: 40vh;
  background: #007B82;

  .line {
    border-bottom: 2px solid var(--color-secondary-light);
    margin: 20px 0;
  }
}

.thumbnail {
  width: 65px;
  height: 65px;
  object-fit: contain;
  object-position: center;
}

.logoColumn {
  .logo {
    max-width: 175px;
    height: auto;
    object-fit: contain;
    object-position: center;
    margin: 5px 0;
    @include device-max("lg") {
      margin: 0 auto;
    }
  }
}

.contentColumn {
  @include device-max("lg") {
    text-align: center;
  }
}

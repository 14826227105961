.backdrop {
  background: rgba(16, 43, 63, 0.6);
}

.container {
  padding: 20px 0px;
  border-radius: 30px 30px 0px 0px;
  font-family: "Helvetica Neue";
  height: fit-content !important;

  @media screen and (min-width: 992px) {
    position: absolute;
    bottom: 0;
  }
  transition: 0.3s ease-in-out;
}

.closeButton {
  svg {
    color: var(--color-secondary);
  }
}

.dependentContainer {
  margin-bottom: 15px;
  background-color: var(--color-base-white);
  border: 1px solid var(--color-progress-background);
  border-radius: 10px;
}

.dependentHeader {
  padding: 20px;
  border-bottom: 1px solid var(--color-progress-background);

  h6 {
    margin: 0 0 0 10px;
    font-size: 14px;
    color: var(--color-text);
  }
}

.dependentBody {
  padding: 20px;
}

.submitCta {
  width: 100%;
  background-color: var(--color-secondary) !important;
  border-color: var(--color-secondary) !important;
}

.dependentInfo {
  h6 {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-text);
  }

  p {
    margin: 0;
    font-size: 14px;
    color: var(--color-premium-gray);
  }
}

.radioGroup {
  padding: 0;
  position: relative;

  label {
    position: absolute;
    left: 48px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: var(--color-text);
  }

  input {
    position: relative;
    margin-left: 0 !important;
    margin-top: 0;
    background-color: white !important;
    border: 2px solid var(--color-progress-background) !important;
    border-radius: 10px !important;
    min-width: 100%;
    min-height: 60px;
    display: grid;
    place-items: center;
    filter: none !important;
    cursor: pointer;

    &:focus, &:active {
      outline: none !important;
      box-shadow: none;
      background-color: white !important;
    }

    &:before {
      content: '';
      position: absolute;
      left: 16px;
      height: 20px;
      width: 20px;
      border-radius: 6px;
      filter: drop-shadow(0px 4px 4px rgba(8, 206, 115, 0.2));
      border: 2px solid var(--color-secondary) !important;
    }

    &:after {
      content: '';
      position: absolute;
      left: 16px;
      height: 20px;
      width: 20px;
      border-radius: 6px;
      background-color: var(--color-secondary);
      transform: scale(0);
      transition: transform 0.2s ease-in-out;
    }

    &:checked[type=radio] {
      position: relative;
      background-color: white !important;
      box-shadow: none;

      &:before {
        border-radius: none !important;
      }

      &:after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e") !important;
        transform: scale(1.05);
      }
    }
  }
}

.description {
  margin: 0;
  font-size: 14px;

  span {
    color: var(--color-secondary);
  }
}

.backdrop {
  background: rgba(16, 43, 63, 0.6);
}

.body {
  padding: 0px;

  .default {
    color: var(--color-secondary);
    margin: 1px 16px 16px 16px;
    cursor: pointer;
  }

  .delete {
    border-color: var(--color-gray) !important;
    border-top: solid;
    border-width: 1px;
    color: #CF6F80;
    margin-bottom: 20px;
    padding: 16px;
    cursor: pointer;
  }

  .default,
  .delete {
    font-size: 16px;
    font-weight: 400;
  }
}

.button {
  margin-bottom: 47px;
}

.container {
  background-color: var(--color-white);
  border-radius: 30px 30px 0px 0px;
  font-family: "Helvetica Neue";
  height: fit-content;
  min-height: 191px;
  @media screen and (min-width: 992px) {
    position: absolute;
  }
  transition: 0.3s ease-in-out;
}

.header {
  padding: 35px 24px 30px 16px;

  .closeButton {
    color: var(--color-premium-gray);
  }
}

.title {
  font-size: 18px;
  font-weight: 500;
}

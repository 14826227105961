.newCard {
  align-items: center;
  background-color: var(--color-white);
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(157, 156, 181, 0.25);
  display: flex;
  height: 70px;
  padding: 26px 16px;
  cursor: pointer;

  .cardIcon {
    margin-right: 16px;
  }

  .chevron {
    color: var(--color-secondary);
    margin-left: auto;
  }
}

@import '@separate/v4/styles/buttons/buttonMixins';
@import '@v4/styles/variables.scss';
@import '@separate/v4/styles/responsive/devicesMixins';

.modalHeader {
  height: 56px;
  position: fixed;
  width: 100vw;
  z-index: 1;
  background-color: $color_white;
  box-shadow: 0px 2px 8px 0px rgba(0, 47, 53, 0.16);
  border: none;
}

.modal {
  :global([class*="modal-content"]) {
    display: block;
    background-color: $background_secondary;
  }
}

.content, .modalBody {
  background-color: $background_secondary;
  position: unset;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow-x: hidden;
  padding: 78px 16px 32px 16px;
  min-height: 50vh;
  @include device-min('md') {
    padding: 110px 16px 62px 16px;
  }
}

.tile {
  width: 35px;
  height: 35px;
  animation: positionChange 0.3s ease-in-out forwards;
}

.backButton {
  background-color: transparent !important;
  color: $body_primary !important;
  box-shadow: none;
  border: none;
  padding-left: 0;
  padding-right: 0;
}

.footer {
  background-color: $color_white;
  padding: 64px 16px;
  flex-direction: column;
  border: none;
  @include device-max("md") {
    padding: 16px;
  }
}

@keyframes positionChange {
  0% {
    transform: translateX(30px);
  }
  100% {
    transform: translateX(0px);
  }
}


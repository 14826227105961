@import "../../../../styles/_buttonmixins.scss";

.backdrop {
  background: rgba(16, 43, 63, 0.6);
}

.body {
  margin: 1px 16px 16px 16px;
  padding: 0px;

  .cardInfo,
  .infoBanner {
    background: var(--color-base-white);
    border-color: var(--color-progress-background);
    border-style: solid;
    border-width: 1px;
    padding: 16px 16px 32px 16px;
    margin-bottom: 34px;
  }

  .cardInfo {
    border-radius: 0px 0px 10px 10px;
  }

  .infoBanner {
    align-items: center;
    border-bottom-style: none;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    margin-bottom: 0px;
  }
}

.button {
  margin-bottom: 47px;
  border-radius: 70px !important;
  background-color: var(--color-secondary) !important;
  border-color: var(--color-secondary) !important;
}

.buttonLight {
  @include button-theme-light(var(--color-secondary));
  border: none;
  box-shadow: none;
  margin-bottom: 47px;
  margin-right: 20px;
  background-color: var(--color-white) !important;

  svg {
    margin-right: 8px;
  }
}

.buttonLight:hover{
  border: none;
}

.checkbox {
  color: var(--color-secondary) !important;
  font-size: 16px;
  font-weight: 500;
  margin: 0 4px 42px;

  input {
    &:checked {
      background-color: var(--color-secondary) !important;
      border-color: var(--color-secondary) !important;
    }
  }
}

.container {
  border-radius: 30px 30px 0px 0px;
  font-family: "Helvetica Neue";
  height: fit-content !important;
  @media screen and (min-width: 992px) {
    position: absolute;
  }
  transition: 0.3s ease-in-out;
}

.header {
  padding: 35px 24px 30px 16px;

  .closeButton {
    color: var(--color-premium-gray);
  }
}

.title {
  font-size: 18px;
  font-weight: 500;
}

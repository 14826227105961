@import "@styles/checkboxmixins";
$main-color: var(--color-secondary);

.textInputField {
  border-bottom: 2px solid var(--color-secondary) !important;
  border-radius: 2.6px !important;

  &::placeholder {
    padding-left: 15px !important;
    font-weight: 400;
    font-size: 14px !important;
    color: var(--color-premium-gray) !important;
  }
}

.radioGroup {
  padding: 0;
  position: relative;

  label {
    position: absolute;
    left: 48px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: var(--color-text);
  }

  input {
    position: relative;
    margin-left: 0 !important;
    margin-top: 0;
    background-color: white !important;
    border: 2px solid white !important;
    border-radius: 6px !important;
    min-width: 100%;
    min-height: 60px;
    display: grid;
    place-items: center;
    box-shadow: 0px 4px 10px 1px rgba(157, 156, 181, 0.15);
    filter: none !important;
    cursor: pointer;

    &:focus, &:active {
      outline: none !important;
      box-shadow: none;
      background-color: white !important;
    }

    &:before {
      content: '';
      position: absolute;
      left: 16px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      filter: drop-shadow(0px 4px 4px rgba(8, 206, 115, 0.2));
      border: 2px solid var(--color-secondary) !important;
    }

    &:after {
      content: '';
      position: absolute;
      left: 21px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: var(--color-secondary);
      transform: scale(0);
      transition: transform 0.2s ease-in-out;
    }

    &:checked[type=radio] {
      position: relative;
      background-color: white !important;
      border: 2px solid var(--color-secondary) !important;
      background-image: none;
      box-shadow: none;

      &:after {
        transform: scale(1);
      }
    }
  }

  @media screen and (max-width: 425px) {
    label {
      left: 35px;
      font-size: 11px !important;
    }

    input {
      min-height: 45px;

      &:before {
        left: 10px;
        height: 15px;
        width: 15px;
      }

      &:after {
        left: 14px;
        height: 7px;
        width: 7px;
      }
    }
  }
}

.checkBox {
  padding: 0;
  position: relative;

  label {
    position: absolute;
    left: 48px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: var(--color-text);
  }

  input {
    position: relative;
    margin-left: 0 !important;
    margin-top: 0 !important;
    border: 2px solid var(--color-progress-background) !important;
    border-radius: 6px !important;
    min-width: 100%;
    min-height: 60px;
    display: grid;
    place-items: center;
    box-shadow: none !important;
    filter: none !important;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      left: 16px;
      height: 20px;
      width: 20px;
      border-radius: 4px;
      border: 2px solid var(--color-secondary) !important;
    }

    &:after {
      content: '';
      position: absolute;
      left: 21px;
      height: 10px;
      width: 10px;
      border-radius: 2px;
      background-color: var(--color-secondary);
      transform: scale(0);
      transition: transform 0.2s ease-in-out;
    }

    &:checked[type="checkbox"] {
      background-color: white;
      background-image: none;
      border: 2px solid var(--color-secondary) !important;

      &:after {
        transform: scale(1);
      }
    }
  }

  @media screen and (max-width: 425px) {
    label {
      left: 35px;
      font-size: 11px !important;
    }

    input {
      min-height: 45px;

      &:before {
        left: 10px;
        height: 15px;
        width: 15px;
      }

      &:after {
        left: 14px;
        height: 7px;
        width: 7px;
      }
    }
  }
}

.formCheck {
  label {
    font-size: 14px;
    line-height: 17px;
    color: var(--color-text);
  }
  input {
    border: 3px solid $main-color;
    filter: drop-shadow(0px 4px 4px rgba(8, 206, 115, 0.2));
    &:checked[type=radio] {
      background-color: $main-color;
      border: 3px solid $main-color;
      background-image: none;
    }
  }
}

.pillButtonChecked {
  position: absolute;
  clip: rect(0,0,0,0);
  pointer-events: none;
  & + .pillCheckButton {
    box-shadow: 0px 4px 10px 1px rgba(157, 156, 181, 0.3);
    border-radius: 40px;
    border: 2px solid;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    padding: 11px 30px;
    @include pillCheckButton-unchecked($main-color);
  }
  &:checked {
    & + .pillCheckButton {
      @include pillCheckButton-checked($main-color);
    }
  }
}

@import '@separate/v4/styles/responsive/devicesMixins';
@import '@v4/styles/variables.scss';

.closeButton {
  background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cg clip-path='url(%23clip0_13227_3842)'%3E %3Cpath d='M12.5864 13.9996L7.63664 9.04987C7.24612 8.65935 7.24612 8.02618 7.63664 7.63566C8.02716 7.24514 8.66033 7.24514 9.05085 7.63566L14.0006 12.5854L18.9503 7.63566C19.3409 7.24514 19.974 7.24514 20.3646 7.63566C20.7551 8.02618 20.7551 8.65935 20.3646 9.04987L15.4148 13.9996L20.3646 18.9494C20.7551 19.3399 20.7551 19.9731 20.3646 20.3636C19.974 20.7541 19.3409 20.7541 18.9503 20.3636L14.0006 15.4138L9.05085 20.3636C8.66033 20.7541 8.02716 20.7541 7.63664 20.3636C7.24612 19.9731 7.24612 19.3399 7.63664 18.9494L12.5864 13.9996Z' fill='white'/%3E %3C/g%3E %3Ccircle cx='14' cy='14' r='13' stroke='white' stroke-width='2'/%3E %3Cdefs%3E %3CclipPath id='clip0_13227_3842'%3E %3Crect width='13.3137' height='13.3137' fill='white' transform='translate(7.34375 7.34277)'/%3E %3C/clipPath%3E %3C/defs%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 100%;
  opacity: 1;
  transform: scale(1.3);

  &:focus, &:active {
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
  }
}

.menuLinks {
  .menuLinksIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    margin-right: 20px;
    svg {
      path {
        fill: $color_white;
      }
    }
  }
  a {
    color: $header_neutral;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    text-decoration: none;
    @include device-max("xl") {
      padding: 0.7rem;
      display: flex;
      align-items: center;
      color: $color_white;
      font-size: 16px;
      &:hover {
        background-color: $color_white;
        border-radius: 8px;
        color: $color_primary;
        text-decoration: none;
        .menuLinksIcon {
          svg {
            path {
              fill: $color_primary;
            }
          }
        }
      }
    }
  }

  @include device-min('xl') {
    margin-left: auto;
    .menuLinksIcon {
      display: none;
    }
    a {
      margin: 0 auto;
      margin-right: 1rem;
      position: relative;
      transition: 0.4s all ease-in-out;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: -2px;
        border-bottom: 2px solid $color_primary;
        transform-origin: center;
        display: none;
      }
      &.active {
        &::after {
          display: block;
          animation: underline 0.2s;
          animation-fill-mode: forwards;
        }
      }
    }
  }
}

@keyframes underline {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

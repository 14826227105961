@import "../../../../styles/_buttonmixins.scss";

.backdrop {
  background: rgba(16, 43, 63, 0.6);
}

.container {
  border-radius: 30px 30px 0px 0px;
  height: fit-content !important;
  min-height: 392px;
  @media screen and (min-width: 992px) {
    position: absolute;
  }
  transition: 0.3s ease-in-out;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 35px 24px 11px;
  width: 100%;

  .closeButton {
    color: var(--color-secondary);
  }
}

.title {
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  justify-content: space-between;
  line-height: 21.98px;
}

.body {
  padding: 0 24px 24px;

  .defaultAndPay,
  .buttonWrapper,
  .informational {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .defaultAndPay {
    overflow: hidden;
  }

  .hiddenDefaultAndPay {
    top: 130px;
    position: relative;
    transition: all .3s ease-in-out;
    height: 100%;
    width: 100%;
  }

  .shown {
    top: 0;
  }

  .payNowButton {
    margin: 32px 0px 0px;
    height: 42px;
    width: 100%;
    max-width: 289px;
  }

  .informational {
    color: var(--color-premium-gray);
    font-size: 14px;
    margin: 24px 0px 0px;
    text-align: center;
  }
}

.checkbox {
  color: var(--color-secondary);
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin: 32px 0px 0px;
}

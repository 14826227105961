@import '@separate/v4/styles/responsive/devicesMixins';
@import '@v4/styles/variables.scss';

.contentContainer {
  border-radius: 10px;
  background: $background_secondary;
  padding: 16px;
  margin-bottom: 16px;
}

p.description {
  font-size: 14px !important;
  color: $body_neutral;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.28px;
}

.bullet {
  font-size: 14px;
  color: $body_neutral;
  font-weight: 400;
  line-height: 18px;
  padding-left: 1.8rem;
  li {
    margin-bottom: 16px;
  }
}

.check {
  input {
    transform: scale(1.4);
    border: 1px solid $background_tertiary !important;
    box-shadow: none !important;
    background-color: $background_secondary;

    &:checked {
      background-color: $color_primary;
      border: 1px solid $color_primary !important;
    }
  }
}

.label {
  font-size: 12px;
  color: $body_neutral;

  a {
    color: $color_primary;
  }
}

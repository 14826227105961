@import '@sepstyles/profilemixins';

.h4 {
  margin-bottom: 16px;
}

.ctaTitle {
  @include ctaTitle();
}

.paymentMethods {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: auto;
  @media only screen and (min-width: 992px) {
    max-height: 380px;
  }
}

$main-color: var(--color-secondary);
$text-color: var(--color-text);

.description {
  font-size: 14px;
  color: $text-color;
}

.bullet {
  font-size: 14px;
  color: $text-color;
  padding-left: 1rem;
}

.check {
  input {
    border: 2px solid $main-color !important;
    box-shadow: none !important;

    &:checked {
      background-color: $main-color;
      border: 2px solid $main-color !important;
    }
  }
}

.label {
  font-size: 12px;
  color: var(--color-premium-gray);

  a {
    color: $main-color;
  }
}

@import '@v4/styles/variables.scss';
@import '@separate/v4/styles/responsive/devicesMixins';

.contentContainer {
  border-radius: 20px;
  background: $color_white;
  padding: 24px 32px;
  max-width: 590px;
  @include device-max('md') {
    width: 100%;
    padding: 16px;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: $header_neutral;
    margin-bottom: 8px;
    @include device-min('xl') {
      font-size: 36px;
      line-height: 44px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $body_neutral;
    @include device-min('xl') {
      font-size: 18px;
      line-height: 28px;
    }
  }

  &.entering {
    animation: fade-out-left 0.8s ease forwards;
  }
}

.top {
  scroll-margin: 100px;
}

@keyframes fade-out-left {
  0% {
    transform: translateX(5%);
  }
  100% {
    transform: translateX(0);
  }
}

.main {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .row {
    min-height: 45vh;
  }
  .logo {
    max-width: 50%;
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
  .title {
    font-weight: 500;
    font-size: 40px !important;
    line-height: 49px;
    text-align: center;
    letter-spacing: -0.99px;
    color: var(--color-white);
  }
  .subtitle {
    font-size: 20px !important;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.41px;
    color: var(--color-white);
  }
  .cta {
    max-width: 278px;
    font-size: 18px;
    line-height: 21px;
    padding-top: 12px;
    padding-bottom: 12px;
    min-width: 218px;
    box-shadow: 0px 6px 3px rgba(0, 123, 130, 0.301961);
  }
}
.about {
  min-height: 20vh;
  padding: 20px 0;
  @media (max-width: 768px) {
    padding: 50px 0;
    min-height: 35vh;
  }
  .title {
    font-weight: bold;
    font-size: 25px !important;
    line-height: 24px;
    letter-spacing: -0.41px;
    color: #007b82;
  }
  .subtitle {
    font-size: 16px !important;
    line-height: 24px;
    letter-spacing: -0.41px;
    color: #59a9ae;
  }
}
.services {
  .col-lg-4 {
    border-top: 6px solid var(--color-white) !important;
    border: none;
    &:nth-child(3n) {
      border-right: 6px solid var(--color-white);
      border-left: 6px solid var(--color-white);
    }
    @media (max-width: 768px) {
      border-left: none !important;
      border-right: none !important;
      border-top: none !important;
      border-bottom: 6px solid var(--color-white) !important;
      &.service-tile {
        &:nth-child(4) {
          border-bottom: none !important;
        }
      }
    }
  }
  .full-height {
    height: 100%;
  }
  .service-main {
    background-color: #d5e3ed;
    min-height: 30vh;
    line-height: 24px;
    color: var(--color-secondary);
    letter-spacing: -0.41px;
    @media (max-width: 768px) {
      border-bottom: 6px solid var(--color-white);
      &.border-btm-none {
        border-bottom: none !important;
      }
    }
    .title {
      font-weight: bold;
      font-size: 25px !important;
    }
    .subtitle {
      font-size: 16px !important;
      max-width: 500px;
    }
    .icon {
      width: 110px;
      height: auto;
    }
    .btn {
      color: var(--color-white);
      &.dropdown {
        font-size: 20px !important;
        svg {
          transition: all 0.3s ease-in-out;
        }
      }
      &.cta {
        min-width: 218px !important;
        font-size: 18px !important;
        padding-top: 10px;
        padding-bottom: 10px;
        box-shadow: 0px 4px 2px rgba(157, 184, 203, 0.15);
      }
    }
  }
  .service-tile {
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--color-white);
    letter-spacing: -0.41px;
    line-height: 24px;
    .logo {
      width: 100%;
    }
    .title {
      font-size: 20px !important;
    }
    .subtitle {
      font-size: 16px !important;
    }
    .cta {
      min-width: 186px;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      padding-top: 6px;
      padding-bottom: 6px;
      color: var(--color-secondary);
      background: #baedcc;
      box-shadow: 0px 4px 2px rgba(157, 184, 203, 0.15);
    }
  }
}
.testimonial {
  padding-top: 25px;
  padding-bottom: 25px;
  .person {
    font-size: 18px !important;
    line-height: 22px;
    text-align: center;
    color: #85a7bf;
  }
  blockquote {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.41px;
    color: var(--color-body);
    position: relative;
    display: inline-flex;
    margin-top: 20px;
    margin-bottom: 20px;
    &::before {
      font-family: inherit;
      content: "\201C";
      color: var(--color-secondary);
      font-size: 60px;
      position: absolute;
      top: -10px;
      left: -2px;
    }
    &::after {
      font-family: inherit;
      content: "\201D";
      color: var(--color-secondary);
      font-size: 60px;
      position: absolute;
      bottom: -25%;
      right: 0;
    }
    span {
      display: block;
      color: #333333;
      font-style: normal;
      font-weight: bold;
      margin-top: 1em;
    }
  }
  .cta {
    max-width: 278px;
    font-size: 18px;
    line-height: 21px;
    padding-top: 12px;
    padding-bottom: 12px;
    box-shadow: 0px 4px 2px rgba(190, 190, 190, 0.26);
  }
}
.follow {
  .row {
    background-color: var(--color-secondary);
    .title {
      font-size: 25px !important;
      line-height: 24px;
      letter-spacing: -0.41px;
      color: var(--color-white);
    }
    .btn {
      background-color: transparent;
      border: none !important;
      img {
        max-width: 48px;
        max-height: 48px;
      }
    }
  }
}

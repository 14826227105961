@import '@v4/styles/variables.scss';
@import '@separate/v4/styles/responsive/devicesMixins';

.headerBar {
  box-shadow: 0px 2px 8px 0px #002F3529;
  :global([class*="navbar-toggler-icon"]) {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 126, 132, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
  }

}

.offCanvas {
  background-color: $color_primary;
  :global([class*="btn-close"]) {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.7072 5.29289C6.31668 4.90237 5.68351 4.90237 5.29299 5.29289C4.90246 5.68342 4.90246 6.31658 5.29299 6.70711L10.5858 12L5.29289 17.2929C4.90237 17.6834 4.90237 18.3166 5.29289 18.7071C5.68342 19.0976 6.31658 19.0976 6.70711 18.7071L12 13.4142L17.293 18.7071C17.6835 19.0976 18.3167 19.0976 18.7072 18.7071C19.0977 18.3166 19.0977 17.6834 18.7072 17.2929L13.4143 12L18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289C18.3166 4.90237 17.6834 4.90237 17.2929 5.29289L12 10.5857L6.7072 5.29289Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    opacity: 1;
    background-size: 95%;
    background-position: center;
  }
}

.logo {
  width: 240px;
  height: 30px;
  background-image: url('../../../../images/logo.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  @include device-max("xl") {
    width: 36px;
    height: 36px;
    background-image: url('../../../../images/home/sep-tile.png');
    border: 1px solid $color_white;
    border-radius: 6px;
  }
}

.profile {
  color: $color_white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .icon {
    margin-right: 1rem;
  }
  p {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.28px;
    margin-bottom: 0;
  }
  span {
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.24px;
    color: $body_primary_light;
  }
}

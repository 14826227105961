$boder-color: var(--color-secondary);
$text-color: var(--color-body);
$error-color: var(--color-error);
$disabled-color: var(--color-input-disabled);

.inputContainer {
  padding-bottom: 25px;
  position: relative;

  .textLabel {
    font-size: 12px;
    line-height: 17px;
    color: $boder-color;
    padding-bottom: 5px;
    position: absolute;
    width: auto;
    left: 0;
    top: 15%;
    visibility: hidden;
  }

  .showTextLabel {
    visibility: visible;
    animation-name: moveLabel;
    transform-origin: left;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
  }

  .textInput {
    background-image: none !important;
    background-color: transparent !important;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid $boder-color;
    font-size: 16px;
    line-height: 17px;
    color: $text-color;
    padding-left: 2px;
    transition: all 0.1s ease-in-out;

    &::placeholder {
      font-size: 12px;
      line-height: 17px;
      color: $boder-color;
      padding-bottom: 5px;
    }

    &:focus,
    &:not(:placeholder-shown) {
      caret-color: $text-color;
      box-shadow: none;
      font-size: 16px;

      & + .textLabel {
        visibility: visible;
        animation-name: moveLabel;
        transform-origin: left;
        animation-fill-mode: forwards;
        animation-duration: 0.5s;
      }
    }

    &:disabled {
      color: $disabled-color;
    }
  }

  .isInvalid {
    color: $error-color;

    &.textInput::placeholder {
      color: $error-color;
    }

    &.textInput {
      border-bottom: 3px solid $error-color;
    }
  }
}

@keyframes moveLabel {
  0% {
    transform: translateX(-20%);
    opacity: 0;
    top: auto;
    bottom: -5px;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
    top: auto;
    bottom: -5px;
  }
}

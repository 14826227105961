@import "../../../../styles/_buttonmixins.scss";

.alertModal {
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 20px;
    height: 343px;

    p {
      text-align: center;
    }

    @media screen and (min-width: 992px) {
      margin: 16px;
    }
  }

  .body {
    text-align: center;

    .card {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
      }

      .textLight {
        color: var(--color-premium-gray);
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }

      svg {
        margin-bottom: 20px;
      }
    }

    .buttonContainer {
      margin-top: 20px;

      button {
        padding: 9px 20px !important;
        border-radius: 70px !important;
      }

      .buttonDark {
        @include button-theme-dark(var(--color-secondary));
      }

      .buttonLight {
        @include button-theme-light(var(--color-secondary));
        border: none !important;
        box-shadow: none !important;
        background-color: var(--color-white) !important;
      }

      .buttonLight:hover{
        border: none !important;
      }

      .buttonTryAgain {
        @include button-theme-dark(var(--color-button-background-red) !important);
      }

    }
  }

  .spinner {
    color: var(--color-secondary);
    
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      margin-top: 20px;
    }
  }
}

.backdrop {
  z-index: 1055;
}

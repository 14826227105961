@mixin infoRow {
  width: 25%;
  color: var(--color-secondary);
  font-size: 12px;
  line-height: 14px;
  margin-right: 12px;
}

@mixin userInfo {
  width: 70%;
  font-weight: 510;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-text);
}

@mixin ctaTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--color-text);
}

@mixin editCta {
  background: none;
  border: none;
  box-shadow: none;
  color: var(--color-secondary);
  font-size: 16px;
  line-height: 19px;
  padding: 0;
  margin-bottom: 8px;

  &:focus,
  &:hover,
  &:active {
    background: none !important;
    color: var(--color-secondary) !important;
    border: none !important;
    border-radius: 0 !important;
  }
}

@mixin ctaContainerMargin{
  height: 56vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@import "../../../../styles/_buttonmixins.scss";

.podersaludUpgradeSubscriptionPlanModal {
  overflow-y: hidden;
  padding-left: 0 !important;

  @media screen and (min-width: 992px) {
    position: absolute;
  }

  .closeModal {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: flex-start;

    a {
      content: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='14' cy='14' r='14' fill='%236E91B1'/%3E%3Cpath d='M8 12L14 18L20 12' fill='%236E91B1'/%3E%3Cpath d='M8 12L14 18L20 12' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }

    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
    }
  }

  &>div>div {
    position: absolute;
    overflow-y: scroll;
    max-height: 550px;
    border-radius: 30px 30px 0px 0px !important;
    background: var(--color-white);
    animation: animatebottom 0.4s;

    @media screen and (min-width: 992px) {
      bottom: -30px;

    @keyframes animatebottom {
      from {
        bottom: -500px;
      }

      to {
        bottom: -60px;
      }
    }
    }

    @media screen and (max-width: 992px) {
      height: fit-content !important;
      bottom: -20px;

    @keyframes animatebottom {
      from {
        bottom: -800px;
      }

      to {
        bottom: -20px;
      }
    }
  }

    p.textLight {
      color: var(--color-premium-gray);
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }

  .content {
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
      color: var(--color-premium-gray);
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      margin-bottom: 0;

      svg {
        margin-bottom: 4px;
      }
    }
  }

  .body {
    margin: 10px;
  }

  .subTitle {
    padding: 0 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  .information {
    padding: 0;

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
    }

    div:first-child>div {
      color: var(--color-premium-gray);
    }
  }

  button {
    border-radius: 70px !important;
    background-color: var(--color-secondary);
    border-color: var(--color-secondary); 
  }

  .buttonLight {
    @include button-theme-light(var(--color-secondary));
    margin-top: 24px;
    background-color: var(--color-white) !important;
  }
}

.backdrop {
  z-index: 1;
}

.dialog {
  @media screen and (max-width: 992px) {
    margin: 0 auto;
    max-width: none;
    min-height: calc(100% - 1rem);
  }
}

.hideModal {
  display: none !important;
}

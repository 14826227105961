@import "../../../../styles/_buttonmixins.scss";

.backdrop {
  background: rgba(16, 43, 63, 0.6);
}

.container {
  border-radius: 30px 30px 0px 0px;
  height: fit-content;
  min-height: 498px;
  @media screen and (min-width: 992px) {
    position: absolute;
  }
  transition: 0.3s ease-in-out;
}

.header {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding: 35px 24px 0px 20px;

  .closeButton {
    color: var(--color-secondary);
    min-width: 28px;
  }
}

.title {
  display: flex;
  flex-direction: column;
  font-size: 30px;
  font-weight: 500;
  line-height: 36.63px;
}

.subtitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 21.98px;
  margin-top: 8px;
}

.body {
  padding: 0 24px;
}

.padded {
  padding-top: 24px !important;
}

.payNowButton,
.changeButton {
  margin: 12px;
  width: 289px;
}

.changeButton {
  @include button-theme-light(var(--color-secondary));
}

.informational {
  color: var(--color-premium-gray);
  font-size: 14px;
  margin: 24px 0px 0px;
  text-align: center;
}

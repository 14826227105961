@import '@sepstyles/variables';
@import './articles.scss';
@import './homepage.scss';
@import './stripeInput.scss';
@import './softKeyboard.scss';

html,
body {
  padding: 0;
  margin: 0;
  font-family: HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

* {
  box-sizing: border-box;
}

.container {
  position: relative;
  &.maxWidth-800 {
    max-width: 800px !important;
  }
  .component {
    //always adds space on the first child div for the fixed headerbar
    & > div:first-child {
      padding-top: var(--header-height) !important;
    }
  }
  .component,
  .componentNoHeader {
    @media (max-width: 768px) {
      .row {
        overflow-x: hidden;
      }
    }
  }
}

.description {
  margin: 4rem 0;
  line-height: 1.5;
  font-size: 1.5rem;
  font-weight: 400;
}

.guideBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

a {
  color: var(--color-link);
  text-decoration: none;
  cursor: pointer;
}

a:hover,
a:focus,
a:active {
  text-decoration: underline;
}

input, select {
  appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
  font-weight: 500;
}

.underlined {
  text-decoration: underline;
}

.widgetBar {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 1em;
  margin-top: 1em;
}

.ctaCard {
  margin-bottom: 1em;
  margin-top: 1em;
}

.chatContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: inherit;
  max-width: 768px;
  margin: 0 auto;
  padding-bottom: 2em;
  text-align: center;
  @media (min-width: 768px) {
    padding-bottom: 4em;
  }
  .messageInputForm {
    margin-top: auto;
    display: flex;
    justify-self: flex-end;
    width: 100%;
    max-width: 768px;
    background: var(--color-white);
    padding-bottom: 1em;
  }
  .messageInputForm > :first-child {
    width: 100%;
  }
  .messages {
    overflow: scroll;
  }
}

.progress {
  border-radius: 10px;
  background: #D5E3ED;
  .progress-bar {
    background: linear-gradient(to right, var(--color-primary), var(--color-secondary));
    border-radius: inherit;
  }
}

@keyframes slideRight {
  0% {
    transform: scaleX(0);
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    opacity: 1;
  }
}

.greetings {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: var(--color-text);
  position: relative;
  display: inline-block;
}

.link {
  font-size: 14px;
  line-height: 17px;
  color: var(--color-secondary);
  &:after {
    content: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M1 11L6 6L1 1' stroke='%23007E84' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E %3C/svg%3E ");
    position: relative;
    left: 4px;
    top: 1px;
  }
}

.backLink {
  font-size: 14px;
  line-height: 17px;
  color: var(--color-secondary);
  &:before {
    content: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M6 11L1 6L6 1' stroke='%23007E84' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E %3C/svg%3E ");
    position: relative;
    padding-right: 4px;
    top: 1px;
  }
}

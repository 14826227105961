@import '@styles/profilemixins';

.statusRow {
  width: 100%;
}

.infoRow {
  @include infoRow();
}

.userInfo {
  @include userInfo();
}

.ctaTitle {
  @include ctaTitle();
}

.editCta {
  @include editCta();
}

.hide {
  display: none;
}

.last4 {
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
}

.largeFont {
  font-size: 16px;
}

.pastDue {
  background: #cf3640;
  border-radius: 10px;
  color: #fff;
  padding: 8px 15px 1px 15px !important;

  span {
    color: #fff;
  }

  .pastDueContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;

    .leftCol {
      .pastDueMessage {
        font-size: 12px;
        margin-bottom: 0 !important;
        width: 95%;
      }
    }

    .rightCol {
      display: flex;
      justify-content: center;
      align-self: center;

      .payNowCta {
        font-size: 14px;
        background-color: #fff;
        border-radius: 70px;
        border: none;
        box-shadow: 0px 4px 10px 1px rgba(157, 156, 181, 0.3);
        color: #cf3640;
        cursor: pointer;
        min-width: 100px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0 !important;
      }
    }
  }
}

.hideEdit {
  display: none;
}

@import "@styles/profilemixins";

.accountInfo {
  min-height: 64vh;
  padding-bottom: 60px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .infoContainer {
    margin-top: 8px;
  }

  .infoRow {
    @include infoRow();
  }

  .userInfo {
    @include userInfo();
  }

  .ctaTitle {
    @include ctaTitle();
  }

  .editCta {
    @include editCta();
  }
}

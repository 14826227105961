@import '@separate/v4/styles/responsive/devicesMixins';
@import '@separate/v4/styles/buttons/buttonMixins';
@import '@v4/styles/variables.scss';

.title {
  font-size: 30px;
  font-weight: 700;
  line-height: 44px;
  color: $color_white;
  @include device-max("xl") {
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;
  }
}

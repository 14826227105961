.defaultIcon {
  align-items: center;
  background: var(--color-base-white);
  border-color: var(--color-progress-background);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  height: 36px;
  justify-content: center;
  margin-right: 16px;
  width: 51px;
}

.infoCard {
  align-items: center;
  border-color: var(--color-progress-background);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  max-height: 58px;
  padding: 11px 16px;
  position: relative;
  width: 100%;
  cursor: pointer;

  .cardIcon {
    height: 36px;
    margin-right: 16px;
    width: 51px;
  }

  .cardNumber,
  .wallet {
    color: var(--color-text);
    font-size: 14px;
    font-weight: 500;
  }

  .expiration {
    color: var(--color-premium-gray);
    font-size: 12px;
    font-weight: 400;
  }

  .meatballs {
    color: var(--color-premium-gray);
    margin-left: auto;
  }

  .pill {
    background-color: #E6FAF7 !important;
    border-color: var(--color-primary);
    border-style: solid;
    border-radius: 40px;
    border-width: 1px;
    color: var(--color-primary);
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    right: 16px;
    top: -12px;
  }
}

.primary {
  border-color: var(--color-primary);
  margin-top: 24px;
}

.selected {
  border-color: var(--color-secondary);
}

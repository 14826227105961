.list {
  text-align: left;
  padding: 5px;
  margin: 0 16px 16px;

  li {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    list-style-image: url("../../icons/InputFieldError.svg");
    color: var(--color-error);
  }
}

.modalBackdrop {
  background: var(--color-text);
  opacity: 0.7;
}

.modalContent {
  border-radius: 20px;
}

.header {
  font-size: 16px;
  font-weight: 600;
}

.textContent {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-premium-gray);
}

.closeButton {
  font-size: 14px;
  background: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
  color: var(--color-secondary);
}

.payButton {
  margin-left: 10px;
  padding: 7px 20px;
  font-size: 14px;
  background-color: var(--color-button-background-red) !important;
  border: 0 !important;
  box-shadow: 0px 4px 10px 1px rgba(157, 156, 181, 0.3);
}

$boder-color: var(--color-secondary);
$text-color: var(--color-body);
$error-color: var(--color-error);
$disabled-color: var(--color-input-disabled);

.inputContainer {
  position: relative;

  .textLabel {
    font-size: 12px;
    line-height: 17px;
  }
  .textInput {
    &:disabled {
      color: $disabled-color;
    }
  }

  .isInvalid {
    color: $error-color;
    &.textInput::placeholder {
      color: $error-color;
    }

    &.textInput {
      border-color: $error-color;
    }
  }

  .disabled {
    opacity: 0.5;
  }
}


@mixin modal-backdrop($color) {
  background-color: $color;
}

@mixin modal-flex-cols() {
  background-color: transparent;
  border: none;
  display: flex;
  margin-top: 35%;
  padding: 0px 62px 0px;
  row-gap: 35px;
}

@mixin modal-blur-fullscreen() {
  backdrop-filter: blur(10px);
}
